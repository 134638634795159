/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Text, FullmapCover, ContactForm, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"qqtgvg99f5"} layout={"l5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--center fs--48" style={{"color":"var(--color-variable-1)"}} content={"Kontaktovať <br>nás môžete pomocou kontaktného formulára alebo na telefonicky"}>
              </Subtitle>

              <Text className="text-box text-box--right" style={{"maxWidth":440}} content={"<span style=\"font-weight: bold;\">World Capital Trade - LL s.r.o.</span><br><span style=\"font-weight: bold;\">Sídlo:</span><br>Lermontovova 911/3<br>811 05, Bratislava<br><span style=\"font-weight: bold;\">Klientské centrum:</span><br>Karpatská 18, Bratislava<br><span style=\"font-weight: bold;\">GSM:</span> 0907 865 803<br><span style=\"font-weight: bold;\">Email:</span> info@wct.sk<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 flex--stretch" columns={"2"}>
            
            <ColumnWrapper >
              
              <FullmapCover place={"Karpatská 3108/18, 811 05, Bratislava"} style={{"overflow":"hidden","borderRadius":"6px"}}>
              </FullmapCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" action={"/contact"} fields={[{"name":"Meno","type":"text","required":true,"placeholder":"Zadajte Vaše meno"},{"name":"Priezvisko","type":"text","required":true,"placeholder":"Zadajte Vaše priezvisko"},{"name":"Kontaktný e-mail","type":"email","required":true,"placeholder":"Zadajte Váš e-mail"},{"name":"Telefónne číslo","type":"text","required":true,"placeholder":"Telefón +421"},{"name":"Druh nehnuteľnosti","type":"select","options":["Byt","Dom","Stavebný pozemok","Chata","Záhrada","Podnikateľský priestor","Iné"],"required":true,"placeholder":"Označte o akú nehnuteľnosť sa jedná"},{"name":"Obec v ktorej sa nehnuteľnosť nachádza","type":"text","required":true,"placeholder":"Napíšte obec, kde sa nehnuteľnosť nachádza"},{"name":"Odhadovaná hodnota nehnuteľnosti","type":"text","placeholder":"Napíšte odhadovanú hodnotu nehnuteľnosti"},{"name":"Správa","type":"textarea","required":false,"placeholder":"Zadajte text správy"},{"name":"Odoslať","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-g943z5 pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Vykúpime Vašu nehnuteľnosť rýchlo a férovo.</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: var(--color-dominant)\">World Capital Trade - LL s.r.o.</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style=\"color: var(--color-dominant)\">+421907865803<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}